<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col lg="12" sm="12">
              <ValidationProvider name="Attachment file" vid="excel_file">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="excel_file"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                  </template>
                  <b-form-file
                    id="excel_data"
                    v-on:change="onFileChange"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-file>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col>
                <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-import')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </ValidationObserver>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
    name: 'Form',
    data () {
      return {
        valid: null,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        errors: [],
        attachment_two: '',
        formData: {
          excel: ''
        }
      }
    },
    created () {
    },
    computed: {
      loading: function () {
        return this.$store.state.commonObj.loading
      }
    },
    watch: {
    },
    methods: {
      onFileChange (e) {
        this.attachment_two = e.target.files[0]
      },
      async saveUpdate () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        var formData = new FormData()
      formData.append('excel_file', this.attachment_two)

        result = await RestApi.postData(teaGardenServiceBaseUrl, 'factory-license-import', formData, config)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.excelImportMessage'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-import')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    }
  }
  </script>
